import styled, { css } from "styled-components";

export const IconButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: none;
}`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 16px 16px 16px;
  background: var(--Fill-Frame-White, var(--Dark-mode-bg, #FFF));
  display: none;

  // Debug:
  /* display: block;
  position: fixed;
  width: 877px;
  top: 0;
  right: 0;
  z-index: 1000; */

  @media print {
    display: block;
  }
}`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 9px;
  margin-bottom: 8px;
  border-bottom: 1px solid #A3A3A3;
`;

export const Heading = styled.span`
  color: var(--Primary-600, #116595);
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
`;

export const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderItemRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const HeaderAuthor = styled.span`
  color: var(--Neutral-900, #171717);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const UnderHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #A3A3A3;
  gap: 7px;
`;

export const Order = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #E5E5E5;
  color: var(--Neutral-800, #262626);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
`;

export const Area = styled.span`
  color: var(--Neutral-900, #171717);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
`;

export const Category = styled.span`
  color: var(--Neutral-600, #525252);
  font-family: Inter;
  font-size: 14px;
  line-height: 24px;
`;

export const Span = styled.span`
  width: 2px;
  height: 26px;
  margin: 0 23px;
  background: #1271a6;
`;

export const Logo = styled.img`
  width: 118px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
}`;

export const Title = styled.span`
  color: var(--Neutral-900, #171717);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}`;

export const SubText = styled.span`
  color: var(--Neutral-700, #404040);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
}`;

export const ListEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 197px;
  border: 1px solid var(--Neutral-400, #A3A3A3);
}`;

export const ActionItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--Neutral-400, #A3A3A3);
  break-inside: avoid;
  break-after: auto;
}`;

export const ActionItemHeader = styled.div`
  border-top: 1px solid var(--Neutral-400, #A3A3A3);
  padding: 8px 10px;
}`;

export const ActionItemContent = styled.div`
  padding: 0 10px;
}`;

export const ActionItemHr = styled.div`
  border-top: 1px solid var(--Neutral-400, #A3A3A3);
}`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const Copy = styled.span`
  color: var(--Neutral-800, #262626);
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px;
`;

interface IText {
  $category?: boolean;
  $goal?: boolean;
  $title?: boolean;
  $p?: boolean;
  $stretch?: boolean;
}

export const Text = styled.span<IText>`
  color: var(--neutral-900, var(--Color-2, #171717));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;

  @media screen and (max-width: 743px) {
    font-size: 9px;
  }

  ${({ $category }) => $category && css`
    color: var(--neutral-600, var(--Text, #525252));
    font-weight: 400;
  `}

  ${({ $goal }) => $goal && css`
    color: var(--generic-black, #000);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    word-break: break-word;
  `}

  ${({ $title }) => $title && css`
    color: var(--Neutral-800, #262626);
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `}

  ${({ $p }) => $p && css`
    color: var(--Neutral-600, #525252);
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    word-break: break-word;
  `}

   ${({ $stretch }) => $stretch && css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  `}
`;

interface IRow {
  $wrap?: boolean;
  $nowrap?: boolean;
  $extra?: boolean;
  $border?: boolean;
  $white?: boolean;
}

export const Row = styled.div<IRow>`
  display: flex;
  background: #F5F5F5;

  ${({ $wrap }) => $wrap && `
    flex-wrap: wrap;
  `}}

  ${({ $nowrap }) => $nowrap && `
    flex-wrap: nowrap;
  `}}

  ${({ $extra }) => $extra && `
    display: none;
  `}

  ${({ $border }) => $border && `
    border: 1px solid var(--Neutral-400, #A3A3A3);
  `}

  ${({ $white }) => $white && `
    background: #FFF;
  `}
`;

interface ICol {
  $half?: boolean;
  $hr?: boolean;
}

export const Col = styled.div<ICol>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;

  @media screen and (max-width: 365px) {
    padding: 4px;
  }

  ${({ $half }) => $half && `
    flex: initial;
    width: 50%;
  `}

  ${({ $hr }) => $hr && css`
    position: relative;

    ::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: calc(100% - 16px);
      margin-top: 8px;
      background-color: #A3A3A3;
    }
  `}
`;
